import { SaveCarbonCreditsCalculatorPredictionPayload } from "../types/carbonCredits";
import {
  CreateFarmCIActionItemPayload,
  CreateFieldCIActionItemPayload,
} from "../types/improve";
import {
  CreateProjectPayload,
  ProjectTypeType,
  TaskStatus,
  UpdateTaskStepPayload,
} from "../types/projects";
import coreAPI from "@/datasources/coreAPI";
import {
  CommentsFromAPI,
  CreateRegisteredInterestPayload,
  PrePropPayloadType,
  RoleType,
  UpdateNotificationPreferencesDto,
  UserType,
  YieldFilters,
} from "@/types/api";
import { RawCustomerDataType } from "@/types/dataTypes";

// ==================================
// USER AND ROLE
// ==================================

export interface ExtractRoleInfoReturn {
  roleType: RoleType | null;
  enterpriseId: number | null;
  organisationId: number | null;
}

/**
 * @param user Extracts the roleType and roleId from the userWithUserRoles object
 */
export const extractRoleInfo = (user: any): ExtractRoleInfoReturn => {
  // This login only supports SUPER_ADMIN, ENTERPRISE_ADMIN and ORGANISATION_ADMIN roles
  // that are linked to a single role

  // If there are multiple enterprise roles that a user has - let the user choose an enterprise
  const enterpriseIds = user?.userRoles
    ?.map((userRole: any) => {
      if (userRole?.role?.type === "ENTERPRISE_ADMIN")
        return userRole.enterpriseId;
      return undefined;
    })
    .filter((enterpriseId: number) => !!enterpriseId);

  if (enterpriseIds?.length >= 1) {
    return {
      roleType: "ENTERPRISE_ADMIN",
      enterpriseId: enterpriseIds?.length === 1 ? enterpriseIds[0] : null,
      organisationId: null,
    };
  }

  // If there is no enterpriseId - let's check if the user has an organisationId
  // Again, if there are multiple roles, we take the first one.
  const organisationAdminRole = user?.userRoles?.find(
    (userRole: { organisationId: any }) => userRole.organisationId !== null
  );

  if (organisationAdminRole) {
    return {
      roleType: "ORGANISATION_ADMIN",
      enterpriseId: organisationAdminRole.organisation.enterpriseId,
      organisationId: organisationAdminRole.organisationId,
    };
  }

  const superAdminRole = user?.userRoles?.find(
    (userRole: any) => userRole?.role?.type === "SUPER_ADMIN"
  );
  if (superAdminRole) {
    return {
      roleType: "SUPER_ADMIN",
      enterpriseId: null,
      organisationId: null,
    };
  }

  return { roleType: null, enterpriseId: null, organisationId: null };
};

export const fetchMyUser = async () => {
  try {
    const { data: userWithUserRoles } = await coreAPI.get(`/users/me`);
    const { roleType } = extractRoleInfo(userWithUserRoles);
    if (roleType === null)
      throw new Error("User does not have a supported role.");
    else return userWithUserRoles;
  } catch (err: any) {
    if (err?.response?.status === 404) {
      throw new Error("User not found");
    }
    throw new Error(err.message);
  }
};

export const setFirstTimePassword = async (
  email: string,
  verificationToken: string,
  password: string
) =>
  coreAPI.post(`/users/first-time-password`, {
    email,
    verificationToken,
    password,
  });

export const registerInterest = async (
  payload: CreateRegisteredInterestPayload
) => {
  const { data } = await coreAPI.post(`/registered-interests`, payload);
  return data;
};

export const deregisterInterest = async (userId: number) => {
  const { data } = await coreAPI.delete(`/registered-interests/${userId}`);
  return data;
};

export const findInterestedUser = async (userId: number) => {
  const { data } = await coreAPI.get(`/registered-interests/${userId}`);
  return data;
};

export const updateUserDetails = async (
  userId: string,
  data: {
    stateName: string;
    value: boolean;
  }
) => {
  const { data: updatedData } = await coreAPI.patch(`/users/${userId}/`, data);
  return updatedData;
};

export const updateProfilePicture = async (
  user: UserType,
  profilePictureUrl: string
) => {
  const { data: updatedData } = await coreAPI.patch(`/users/${user.id}/`, {
    profilePictureUrl,
  });
  return updatedData;
};

export const updateUserPreference = async (
  userId: string,
  preferenceKey: string,
  preferenceValue: any
) => {
  const { data: updatedData } = await coreAPI.patch(`/users/${userId}/`, {
    [preferenceKey]: preferenceValue,
  });
  return updatedData;
};

export const updateNotificationPreferences = (
  userId: string,
  preferences: UpdateNotificationPreferencesDto
) => {
  return coreAPI.patch(
    `/users/${userId}/notification-preferences`,
    preferences
  );
};

export const updateUserProfilePicture = async (
  userId: string,
  profilePicture: File
) => {
  const formData = new FormData();
  formData.append("file", profilePicture);
  const { data } = await coreAPI.patch(
    `/users/${userId}/profile-pictures`,
    formData
  );
  return data;
};

export const deleteUserProfilePicture = async (userId: string) => {
  const { data } = await coreAPI.delete(`/users/${userId}/profile-pictures`);
  return data;
};

export const fetchMentionableUsers = async () => {
  const { data } = await coreAPI.get(`/users/mentionable`);
  return data;
};

export const updateUserState = async (
  userId: string,
  data: {
    stateName: string;
    value: boolean;
  }
) => {
  const { data: updatedData } = await coreAPI.patch(
    `/users/${userId}/states`,
    data
  );
  return updatedData;
};

// ==================================
// ENTERPRISE
// ==================================

export const fetchAllOrgsInEnt = async (entId: string) => {
  const { data } = await coreAPI.get(`/enterprises/${entId}/organisations`);
  return data;
};

export const fetchAllFarmsInEnt = async (entId: string) => {
  const { data } = await coreAPI.get(`/enterprises/${entId}/farms`);
  return data;
};

export const fetchFarmsInEntNotEngagedInCIItem = async (
  cIItemId: number,
  entId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${entId}/farms/not-engaged/${cIItemId}`
  );
  return data;
};

export const fetchAllFieldsInEnt = async (entId: string) => {
  const { data } = await coreAPI.get(`/enterprises/${entId}/fields`);
  return data;
};

export const fetchFieldsInEntNotEngagedInCIItem = async (
  cIItemId: number,
  entId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${entId}/fields/not-engaged/${cIItemId}`
  );
  return data;
};

export const fetchYieldData = async (
  enterpriseId: string,
  filters?: YieldFilters
) => {
  const { data } = await coreAPI.get(`/enterprise/${enterpriseId}/yields`, {
    params: {
      ...(filters?.startDate && { startDate: filters.startDate.toISOString() }),
      ...(filters?.endDate && { endDate: filters.endDate.toISOString() }),
      ...(filters?.crop?.id && { cropIds: filters.crop.id }),
      ...(filters?.varietyIds?.length && {
        varietyIds: filters.varietyIds.join(","),
      }),
      ...(filters?.farmIds?.length && { farmIds: filters.farmIds.join(",") }),
    },
  });
  return data;
};

// ==================================
// ORGANISATION
// ==================================

export const fetchAllFarmsInOrg = async (
  entId: string,
  organisationId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${entId}/organisations/${organisationId}/farms`
  );
  return data;
};

export const fetchFarmsInOrgNotEngagedInCIItem = async (
  cIItemId: number,
  entId: string,
  organisationId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${entId}/organisations/${organisationId}/farms/not-engaged/${cIItemId}`
  );
  return data;
};

export const fetchAllFieldsInOrg = async (
  entId: string,
  organisationId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${entId}/organisations/${organisationId}/fields`
  );
  return data;
};

export const fetchFieldsInOrgNotEngagedInCIItem = async (
  cIItemId: number,
  entId: string,
  organisationId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${entId}/organisations/${organisationId}/fields/not-engaged/${cIItemId}`
  );
  return data;
};

// ==================================
// CONTINUOUS IMPROVEMENT
// ==================================

export const fetchAllCIItems = async (version: number) => {
  const { data: CIItemsFetched } = await coreAPI.get(
    `/ci-items/version/${version}`
  );
  return CIItemsFetched;
};

export const assignCIActionItemToFields = async ({
  selectedFieldIds,
  ciItemId,
}: CreateFieldCIActionItemPayload) => {
  const payload = {
    fieldAssignments: selectedFieldIds.map((fieldId) => ({ fieldId })),
  };

  const { data } = await coreAPI.post(
    `/ci-action-items/${ciItemId}/assignToFields`,
    payload
  );
  return data;
};

export const assignCIActionItemToFarms = async ({
  selectedFarmIds,
  ciItemId,
}: CreateFarmCIActionItemPayload) => {
  const payload = {
    farmAssignments: selectedFarmIds.map((farmId) => ({ farmId })),
  };

  const { data } = await coreAPI.post(
    `/ci-action-items/${ciItemId}/assignToFarms`,
    payload
  );
  return data;
};

export const fetchCiActionItem = async (id: string) => {
  const { data } = await coreAPI.get(`/ci-action-items/${id}`);
  return data;
};
export const fetchCiActionItemWithResources = async (id: string) => {
  const { data } = await coreAPI.get(`/ci-action-items/${id}/resources`);
  return data;
};

export const fetchCiItem = async (id: string) => {
  const { data } = await coreAPI.get(`/ci-items/${id}`);
  return data;
};

export const fetchCiItemWithResources = async (
  id: string,
  engagedFarms?: boolean,
  engagedFields?: boolean
) => {
  const { data } = await coreAPI.get(
    `/ci-items/${id}/resources/?engagedFarms=${engagedFarms}&engagedFields=${engagedFields}`
  );
  return data;
};

type CreateCommentPayload = {
  ciActionItemId: number;
  comment: string;
  authorId: number;
  actionUrl?: string;
  parentCommentId?: number;
  mentions?: { mentionedUserId: number }[];
};

type UpdateCommentPayload = {
  ciActionItemId: number;
  comment: string;
  commentId: number;
  mentions?: { mentionedUserId: number }[];
};

export const fetchCommentsForActionItem = async (
  ciActionItemId: number
): Promise<CommentsFromAPI[]> => {
  const { data } = await coreAPI.get(
    `/ci-action-items/${ciActionItemId}/comments`
  );
  return data;
};

export const postCommentForActionItem = async (
  createCommentPayload: CreateCommentPayload
) => {
  try {
    const response = await coreAPI.post(
      `ci-action-items/${createCommentPayload.ciActionItemId}/comments`,
      createCommentPayload
    );
    const newComment = response.data;

    return newComment;
  } catch (e) {
    console.error("Error posting comment:", e);
    throw e;
  }
};

export const editCommentForActionItem = async (
  updateCommentPayload: UpdateCommentPayload
) => {
  try {
    await coreAPI.patch(
      `ci-action-items/${updateCommentPayload.ciActionItemId}/comments`,
      updateCommentPayload
    );
  } catch (e) {
    console.error("Error editing comment:", e);
    throw e;
  }
};

export const deleteCommentForActionItem = async (commentId: number) => {
  try {
    await coreAPI.delete(`ci-action-items/comments/${commentId}`);
  } catch (e) {
    console.error("Error deleting comment:", e);
  }
};

export const getCommentsForActionItem = async (
  ciActionItemId: number
): Promise<CommentsFromAPI[]> => {
  const { data } = await coreAPI.get(
    `/ci-action-items/${ciActionItemId}/comments`
  );
  return data;
};

export const fetchFarmSAMCIItems = async (farmId: number) => {
  const { data } = await coreAPI.get(`/sam-ci-items/farm/${farmId}`);
  return data;
};

export const fetchSAMCIItem = async (id: number) => {
  const { data } = await coreAPI.get(`/sam-ci-items/${id}`);
  return data;
};

// ==================================
// ENTERPRISES
// ==================================

export const getAllEnterprises = async (userId?: number) => {
  const { data } = await coreAPI.get(
    `/enterprises`,
    userId
      ? {
          params: { userId },
        }
      : undefined
  );
  return data;
};

export const getEnterpriseData = async (enterpriseId: string) => {
  const { data } = await coreAPI.get(`/enterprises/${enterpriseId}`);
  return data;
};

export const getEnterpriseOrganisationData = async (enterpriseId: string) => {
  const { data } = await coreAPI.get(
    `/enterprises/${enterpriseId}/organisations/`
  );
  return data;
};

// ==================================
// PREDICTED CARBON CREDITS
// ==================================

export const saveCarbonCreditsCalculatorPrediction = async (
  payload: SaveCarbonCreditsCalculatorPredictionPayload
) => {
  const { data } = await coreAPI.post(
    `/enterprise/${payload.enterpriseId}/predicted-carbon-credits`,
    payload
  );
  return data;
};

export const getEnterpriseCarbonCreditPredictions = async (
  enterpriseId: number
) => {
  const { data } = await coreAPI.get(
    `/enterprise/${enterpriseId}/predicted-carbon-credits`
  );
  return data;
};

// ==================================
// ORGANISATIONS
// ==================================

export const getSingleOrganisationData = async (
  enterpriseId: string,
  organisationId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${enterpriseId}/organisations/${organisationId}`
  );
  return data;
};

export const getFarmsFieldCiActionItemsByOrganisations = async (
  enterpriseId: string,
  organisationId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${enterpriseId}/organisations/${organisationId}/farms-with-all-ci-action-items/2`
  );
  return data;
};

export const getAllFieldsForEnterprise = async (enterpriseId: string) => {
  const { data } = await coreAPI.get(`/enterprises/${enterpriseId}/fields`);
  return data;
};

export const getEnterpriseCentroids = async (enterpriseId: string) => {
  const { data } = await coreAPI.get(
    `/enterprises/${enterpriseId}/fields/centroids`
  );
  return data;
};

export const getEnterprisePolygons = async (enterpriseId: string) => {
  const { data } = await coreAPI.get(
    `/enterprises/${enterpriseId}/fields/polygons`
  );
  return data;
};

// ==================================
// FARMS
// ==================================

export const fetchFarmData = async (
  entId: string,
  orgId: string,
  farmId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${entId}/organisations/${orgId}/farms/${farmId}`
  );
  return data;
};

export const getFarmDataByEnterprise = async (enterpriseId: string) => {
  const { data } = await coreAPI.get(`/enterprises/${enterpriseId}/farms`);
  return data;
};

export const getFarmsFieldCiActionItemsByEnterprise = async (
  enterpriseId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${enterpriseId}/farms-with-all-ci-action-items/2`
  );
  return data;
};

export const getSingleFarmFieldCiActionItems = async (
  enterpriseId: string,
  organisationId: string,
  farmId: string
) => {
  const { data } = await coreAPI.get(
    `/enterprises/${enterpriseId}/organisations/${organisationId}/farms/${farmId}/with-all-ci-action-items/2`
  );
  return data;
};

// ==================================
// RESOURCES
// ==================================

export const fetchAllResourcesForCiItem = async (ciItemId: string) => {
  const { data } = await coreAPI.get(`/ci-items/${ciItemId}/resources`);
  return data;
};

// ==================================
// RAW CUSTOMER DATA
// ==================================

export const fetchRawCustomerData = async (enterpriseId: string) => {
  const { data } = await coreAPI.get(`raw-data/enterprise/${enterpriseId}`);
  return data;
};

export const fetchRawCustomerProjectDataData = async (
  projectTaskId: string
) => {
  const { data } = await coreAPI.get(`raw-data/project-task/${projectTaskId}`);
  return data;
};

export const fetchRawCustomerPrePropData = async (
  prePropPayload: PrePropPayloadType
) => {
  const { data } = await coreAPI.post(
    `raw-data/enterprise/${prePropPayload.enterpriseId}/get-prepop-csv/`,
    prePropPayload
  );
  return data;
};

export const postRawCustomerData = async (
  formData: FormData
): Promise<RawCustomerDataType> => {
  const { data } = await coreAPI.post(`/raw-data/customer-file`, formData);
  return data;
};

export const deleteItem = async (itemId: string) => {
  const { data } = await coreAPI.delete(`/raw-data/${itemId}`);
  return data;
};

export const createPublicSheet = async (
  enterpriseId: string,
  projectTaskId: string
) => {
  const { data } = await coreAPI.post(
    `/raw-data/enterprise/${enterpriseId}/create-public-sheet/${projectTaskId}`
  );
  return data;
};

// ==================================
// SCORE
// ==================================

export const fetchScoreData = async (farmId: string) => {
  const { data } = await coreAPI.get(`/score/${farmId}`);
  return data;
};

export const fetchOrganisationScoreData = async (organisationId: string) => {
  const { data } = await coreAPI.get(`score/organisation/${organisationId}`);
  return data;
};

export const fetchEnterpriseScoreData = async (enterpriseId: string) => {
  const { data } = await coreAPI.get(`score/enterprise/${enterpriseId}`);
  return data;
};

export const fetchEnterpriseOrganisationScoresData = async (
  enterpriseId: string
) => {
  const { data } = await coreAPI.get(
    `score/enterpriseOrganisations/${enterpriseId}`
  );
  return data;
};
export const fetchEnterpriseFarmScoresData = async (enterpriseId: string) => {
  const { data } = await coreAPI.get(`score/enterpriseFarms/${enterpriseId}`);
  return data;
};

export const fetchOrganisationFarmScoresData = async (
  organisationId: string
) => {
  const { data } = await coreAPI.get(
    `score/organisationFarms/${organisationId}`
  );
  return data;
};

// ==================================
// PROJECTS
// ==================================

export const fetchEnterpriseProjects = async (
  enterpriseId: string,
  projectType?: ProjectTypeType
) => {
  const { data } = await coreAPI.get(
    `enterprises/${enterpriseId}/projects${
      projectType ? `?projectTypeType=${projectType}` : ""
    }`
  );
  return data;
};

export const fetchProject = async (enterpriseId: string, projectId: string) => {
  const { data } = await coreAPI.get(
    `enterprises/${enterpriseId}/projects/${projectId}`
  );

  return data;
};

export const toggleProjectTaskStatus = async ({
  enterpriseId,
  projectId,
  taskId,
}: {
  enterpriseId: string;
  projectId: number;
  taskId: number;
}) => {
  const { data } = await coreAPI.patch(
    `enterprise/${enterpriseId}/project/${projectId}/project-tasks/${taskId}/toggle-status`
  );
  return data;
};

export const completeProjectTask = async ({
  enterpriseId,
  projectId,
  taskId,
}: {
  enterpriseId: string;
  projectId: number;
  taskId: number;
}) => {
  const { data } = await coreAPI.patch(
    `enterprise/${enterpriseId}/project/${projectId}/project-tasks/${taskId}/mark-as-completed`
  );
  return data;
};

export const inProgressProjectTask = async ({
  enterpriseId,
  projectId,
  taskId,
}: {
  enterpriseId: string;
  projectId: number;
  taskId: number;
}) => {
  const { data } = await coreAPI.patch(
    `enterprise/${enterpriseId}/project/${projectId}/project-tasks/${taskId}/mark-as-in-progress`
  );
  return data;
};

export const notStartedProjectTask = async ({
  enterpriseId,
  projectId,
  taskId,
}: {
  enterpriseId: string;
  projectId: number;
  taskId: number;
}) => {
  const { data } = await coreAPI.patch(
    `enterprise/${enterpriseId}/project/${projectId}/project-tasks/${taskId}/mark-as-not-started`
  );
  return data;
};

// TODO: update to use name instead of ID
export const createProject = async (
  createProjectPayload: CreateProjectPayload
) => {
  const { data } = await coreAPI.post(
    `/enterprises/${createProjectPayload.enterpriseId}/projects`,
    createProjectPayload
  );
  return data;
};

export const fetchProjectTypes = async (category: string) => {
  const { data } = await coreAPI.get(`/project-types/${category}`);
  return data;
};

export const deleteAllProjectsByEnterpriseId = async (enterpriseId: string) => {
  const { data } = await coreAPI.delete(
    `/enterprises/${enterpriseId}/projects`
  );
  return data;
};

export const updateTaskStatus = async ({
  enterpriseId,
  projectId,
  taskId,
  status,
}: {
  enterpriseId: string;
  projectId: number;
  taskId: number;
  status: TaskStatus;
}) => {
  const { data } = await coreAPI.patch(
    `/enterprise/${enterpriseId}/project/${projectId}/project-tasks/${taskId}/update-status`,
    { status }
  );

  return data;
};

// ==================================
// TASK STEP RESPONSES
// ==================================

// Get single task step response

export const fetchSingleTaskStepResponse = async (
  projectId: string,
  taskId: string,
  stepId: string,
  responseId: string
) => {
  const { data } = await coreAPI.get(
    `projects/${projectId}/tasks/${taskId}/steps/${stepId}/responses/${responseId}`
  );

  return data;
};

export const fetchTaskResponses = async (projectId: string, taskId: string) => {
  const { data } = await coreAPI.get(
    `projects/${projectId}/tasks/${taskId}/responses`
  );
  return data;
};

// Get single task step response

export const updateTaskStepResponse = async (
  projectId: string,
  taskId: string,
  stepId: string,
  responseId: string,
  updateTaskStepResponseDto: UpdateTaskStepPayload
) => {
  const { data } = await coreAPI.patch(
    `projects/${projectId}/tasks/${taskId}/steps/${stepId}/responses/${responseId}`,
    updateTaskStepResponseDto
  );
  return data;
};
